import {FC} from 'react';
import Image from 'components/image/Image';
import {UserNoPhoto} from 'modules/theme/components/assets/noPhoto/UserNoPhoto';

interface IUserPhoto {
    width: number;
    photoUrl?: string;
    alt?: string;
    title?: string;
}

export const UserPhoto: FC<IUserPhoto> = ({width, photoUrl, alt, title}) => {
    return <>
        {photoUrl
            ? <Image
                fill
                src={photoUrl}
                alt={alt || 'Fotka užívatela'}
                title={title}
                style={{
                    objectFit: 'cover'
                }}
            />
            : <UserNoPhoto width={width}/>
        }
    </>;
};
